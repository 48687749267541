_ = require("underscore")._;

/**
 * This library calculates a number of queue-statistics from the CDR logs and the real-time XMPP data.
 *
 * Call with LisaApiStats(connection), where connection is a Lisa.Connection.
 * This library will create a .stats object for each Queue-object, and attach the calculated statistics in that object.
 */
(function() {

    /*
     * Attach library under 'LisaApiStats' global.
     * Can be accessed as 'lib' within the library
     */
    var root = this;
    var lib = root.LisaApiStats = {};

    /* Configuration */
    var REDOWNLOADTIME = 60;            // First re-download attempt
    var EXPBACKOFF = 2;                 // Multiply the REDOWNLOADTIME by this factor for every next attempt.
    var MAXREDOWNLOADTIME = 126 * 60;   // Calls run for at most 2 hours. CDR is generated every 5 mins + processing time. so the very last CDR entry can be at 2h06m. Don't download CDRs again after we have downloaded all entries in after 2:06.

    /* Includes */
    var moment = require("moment");
    var Papa = require("papaparse");

    lib.conn = null;
    lib.model = null;
    lib.companyId = null;
    lib.reDownloadTimer = null;

    /* Errors */

    function NoCdrDownloadRightsError(message) {
        this.name = 'NoCdrDownloadRightsError';
        this.message = message || 'User has no rights to download CDRs. Can\'t process statistics from earlier today.';
        this.stack = (new Error()).stack;
    }
    NoCdrDownloadRightsError.prototype = Object.create(Error.prototype);
    NoCdrDownloadRightsError.prototype.constructor = NoCdrDownloadRightsError;


    lib.setConnection = function(conn) {
        lib.conn = conn;

        conn.getCompanyId().done(function(companyId){
            lib.companyId = companyId;
        });
        conn.getModel().done(gotModel);
    }

    lib.setErrorCallback = function (cb) {
        lib.errCallback = cb;
    }

    function gotModel(model) {
        lib.model = model;
        initQueues();
        listenToQueues();
        retrieveCdrs();

        // Periodic tasks
        scheduleCdrReDownload();
        resetStatsAtMidnight();


        lib.model.queueListObservable.addObserver(resetStatsOnReconnect);
    }

    var debouncedRetrieveCdrs = _.debounce(retrieveCdrs, 1000);
    function resetStatsOnReconnect() {
        console.log("STATS: Re-initializing because a queue was added or removed, or we have reconnected.");
        resetStats();
        listenToQueues();
        debouncedRetrieveCdrs();
    }


    function initQueue(queue) {
        console.log("Initializing queue " + queue.name);

        var stats = queue["stats"] || {};
        stats = _.extend(stats, {
            processedCalls: [],
            dailyCalls: 0,
            dailyPickedUpCalls: 0,
            dailySumWaitingTime: 0,
            dailyMaxWaitingTime: 0
        });
        queue.stats = stats;
        stats.observable = stats.observable || new Lisa.Observable();
    }

    /* Create or reset the 'stats' object for all queues */
    function initQueues() {
        for (var queueId in lib.model.queues) {
            var queue = lib.model.queues[queueId];
            initQueue(queue);
        }
    }

    function retrieveCdrs() {
        var date = moment().format("YYYY-MM-DD");
        var url = getCdrDownloadUrl(lib.companyId, date, "queue");

        $.ajax(url, {
            dataType: "text",
            headers: {
                Authorization: Lisa.Connection.restAuthHeader
            },
            success: cdrsRetrieved
        });

    }

    function cdrsRetrieved(response) {
        var startTime = Date.now();

        if (response.indexOf("Forbidden") != -1) {
            if (lib.errCallback) lib.errCallback(new NoCdrDownloadRightsError());
            console.log("STATS: User doesn't have sufficient rights. Not processing CDRs and cancelling re-download. Reload if user-rights have been changed.");
            clearTimeout(lib.reDownloadTimer);
            return;
        }

        // Parse the CSV
        // Possible optimisation; Papaparse can work on urls directly, and will in that case stream the data. This could save some memory. Additionally, Papaparse can do the parsing and processing in worker-threads.
        var papaObj = Papa.parse(response, {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true
        });
        var parsed = papaObj.data;
        for (var len = parsed.length, i=0; i<len; ++i) {
            var row = parsed[i];

            // Check if call answered.
            var answered = true;
            if ((row["agent_id"] === null) || (row["agent_duration"] === null)) {
               answered = false;
            }

            // get Queue
            var queueId = row["queue_id"];
            var queue = lib.model.queues[queueId];
            if (!queue) {
                console.log("STATS: WARN - Queue with id " + queueId + " can't be found in company.");
                continue;
            }

            // get wait duration.
            var waitDuration = parseInt(row["wait_duration"]);
            if (isNaN(waitDuration)) {
                console.log("STATS: WARN - CDR wait_duration " + row["wait_duration"] + " is not a number. Skipping row.");
                continue;
            }

            // Process statistics
            var callId = row["callid"];
            updateQueueStatsForCall(callId, queue, waitDuration, answered, false);
        }

        // Send notify for all queues.
        for (var queueId in lib.model.queues) {
            var queue = lib.model.queues[queueId];
            queue.stats.observable.notify(queue);
        }

        var endTime = Date.now();
        console.log("STATS: Processed " + len + " CDR records in " + (endTime - startTime) + "ms");
    }

    function updateQueueStatsForCall(callId, queue, waitDurationInSecs, answered, notify) {
        var notify = (typeof notify !== 'undefined') ? notify : true; // Notify is true by default.

        if (queue.stats.processedCalls.indexOf(callId) != -1) {
            //console.log("STATS: Call with callid " + callId + " already processed for queue " + queue + " ... skipping");
            return;
        }
        // console.log("STATS: Updating statistics for call " + callId + " for queue " + queue.name + ". waitDurationInSecs:" + waitDurationInSecs + " answered:" + answered);

        /*
         * Possible optimisation; We could ignore calls that we know are 'old enough', and prune these from the processedCalls list as well.
         * However, with even the busiest companies processing less than 1k queue-calls a day, these 100k to 1M of memory-savings aren't worth the effort and added complexity.
         */
        queue.stats.processedCalls.push(callId);
        ++queue.stats.dailyCalls;
        queue.stats.dailySumWaitingTime += waitDurationInSecs;
        if (answered) { ++queue.stats.dailyPickedUpCalls; }
        if (queue.stats.dailyMaxWaitingTime < waitDurationInSecs) {
            queue.stats.dailyMaxWaitingTime = waitDurationInSecs;
        }

        if (notify) { queue.stats.observable.notify(queue); }
    }

    function getCdrDownloadUrl(companyId, date, eventType) {
        return "https://files." + LOGINDATA.base_domain + "/cdr/" + companyId + "/" + date + "/" + eventType + ".csv";
    }

    function listenToQueues() {
        for (var queueId in lib.model.queues) {

            // the model.users array contains all users, keyed by user-id.
            var queue = lib.model.queues[queueId];

            // Then, subscribe or re-subscribe to changes on the user.
            queue.observable.removeObserver(queueChanged);
            queue.observable.addObserver(queueChanged);
        }
    }

    function queueChanged(queue, type, item) {
        // Store when calls entered a queue, so we can track the time that a call spend in a queue.
        if (type == Lisa.Queue.EventTypes.CallAdded) {
            var call = item;
            call.enteredQueueTime = Date.now();
            console.log("STATS: Call " + call.id + " added to queue " + queue);
        }

        if (type == Lisa.Queue.EventTypes.CallRemoved) {
            var call = item;
            var leftQueueTime = Date.now();
            console.log("STATS: Call " + call.id + " removed from queue " + queue);

            // Skip calls for which we don't know when they entered the queue.
            if (!call["enteredQueueTime"]) {
                console.log("STATS: App not started when call " + call.id + " entered queue. Waiting for statistics for this call to come in through CDRs");
                return;
            }

            // A way to determine whether a call was answered or hung up in LisaApi, is to see whether the call still exists on the platform after all events have been processed.
            // This is necessary because it's possible that the event that the call has been removed from the queue, before anything else.
            _.delay(function(){

                // Has the call been answered?
                var answered = false;
                for (userId in lib.model.users) {
                    var userI = lib.model.users[userId];
                    if (userI.calls[call.id]) {
                        answered = true;
                        break;
                    }
                }

                var waitDurationInSecs = (leftQueueTime - call.enteredQueueTime) / 1000;
                delete call.enteredQueueTime;

                updateQueueStatsForCall(call.id, queue, waitDurationInSecs, answered);
            }, 100)

        }
    }

    function scheduleCdrReDownload() {
        if (REDOWNLOADTIME > (MAXREDOWNLOADTIME * EXPBACKOFF)) {
            console.log("STATS: Not downloading CDRs again.");
            return;
        }

        console.log("STATS: Scheduling to download CDRs again after " + REDOWNLOADTIME + " seconds");
        lib.reDownloadTimer = _.delay(function() {
            REDOWNLOADTIME *= EXPBACKOFF;
            retrieveCdrs();
            scheduleCdrReDownload();

        }, REDOWNLOADTIME * 1000)
    }

    function resetStatsAtMidnight() {
        var now = new Date();
        var night = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + 1, // the next day, ...
            0, 0, 0 // ...at 00:00:00 hours
        );
        var msToMidnight = night.getTime() - now.getTime();

        setTimeout(function() {
            resetStats();
            resetStatsAtMidnight();
        }, msToMidnight + 1);
    }

    function resetStats() {
        console.log("STATS: Resetting statistics.");
        initQueues();

        // Send notify for all queues.
        for (var queueId in lib.model.queues) {
            var queue = lib.model.queues[queueId];
            queue.stats.observable.notify(queue);
        }
    }
    lib.resetStats = resetStats;

    exports.LisaApiStats = lib;
}).call(this);