$ = require('jquery');
jQuery = $;
require('floatthead');


/*
 * Unfortunately, dashing doesn't support adding of widgets at runtime. We are forced to defer loading Dashing and all
 * its dependencies until we know which widgets we are going to want.
 */
loadDashing = function() {
    Batman = require('../lib/batman.js');

    require('../lib/batman.jquery.js');
    require('../assets/javascripts/dashing.coffee');
    require('../assets/javascripts/gridster/jquery.gridster.js');
    require('../assets/javascripts/gridster/jquery.leanModal.min.js');
    require('../assets/javascripts/application.coffee');
    var d3 = require('d3');
    require('../assets/javascripts/dashing.gridster.coffee');
    require('../assets/javascripts/jquery.knob.js');
    Rickshaw = require('rickshaw');
    moment = require('moment');

    // Widgets
    require('../widgets/clock/clock.coffee');
    require('../widgets/comments/comments.coffee');
    require('../widgets/duration/duration.coffee');
    require('../widgets/graph/graph.coffee');
    require('../widgets/iframe/iframe.coffee');
    require('../widgets/image/image.coffee');
    require('../widgets/list/list.coffee');
    require('../widgets/meter/meter.coffee');
    require('../widgets/number/number.coffee');
    require('../widgets/text/text.coffee');
    require('../widgets/timeinterval/timeinterval.coffee');
    require('../widgets/dualnumber/dualnumber.coffee');

    // Jobs
    //require('../jobs/buzzwords.job.js');
    //require('../jobs/convergence.job.js');
    //require('../jobs/sample.job.js');
    require('../jobs/queuestats.job.js');
    
    // Pass the number of columns to Dashing
    Dashing.numColumns = window.numColumns;
};

var conn;   // Connection object
var model;  // Datamodel object
USERNAME = "", PASS = "", DOMAIN = "", COMPANYNAME = "", LOGINDATA = {};
var loggedIn = false;
queueSettings = null;

remoteStorage = require('./RemoteStorage-new.js').remoteStorage;
compassHandler = require('./compassHandler.js');
login = require('./login.js');
admin = require('./admin.js');
