(function() {

    var loggingIn = false;
    resolveServer = require("./resolveServer.js").ResolveServer;
    LisaApiStats = require("../lib/LisaApi-stats.js").LisaApiStats;

    $(document).ready(function() {
        $('#login_submit').click(function() {
            //console.log("submit clicked");
            if (!loggingIn) {
                loggingIn = true;
                _.delay(login, 100);
            }
            return false;
        });

        $( "#logout_button" ).click(function() {
            logout();
        });

        $('#login').show();
        
        var username = localStorage ? localStorage.getItem('wallboard_username') : null;
        var password = localStorage ? localStorage.getItem('wallboard_password') : null;
        if (username) {
            $('#login_username').val(username);
            $('#login_password').val(password);
            login();
        }
    });

    function logout() {
        console.log("Logging out.");
        localStorage.removeItem('wallboard_username');
        localStorage.removeItem('wallboard_password');
        location.reload();
    }

    function login() {

        $('#login').hide();
        $('#controls nav').removeClass('hidden');
        $('#login-placeholder').fadeOut(300);
        //$('#loading').show();
        //$('#login_error').hide();

        var username = $('#login_username').val();
        var password = PASS = $('#login_password').val();
        localStorage.setItem('wallboard_username', username);
        localStorage.setItem('wallboard_password', PASS);

        var parseLoginDeferred = resolveServer.parseLogin(username);
        parseLoginDeferred.done(function(parsedLogin) {

            //console.log(parsedLogin);
            LOGINDATA = parsedLogin;
            USERNAME = parsedLogin.username;
            DOMAIN = parsedLogin.domain;
            COMPANYNAME = null;

            conn = new Lisa.Connection();

            // Setup logging and status messages.
            conn.logging.setCallback(function(msg) {
                console.log(msg);
            });

            // Setup connection-status callback.
            conn.connectionStatusObservable.addObserver(connectionStatusCallback);

            conn.connect(parsedLogin.bosh_server, parsedLogin.jid, PASS);
            LisaApiStats.setConnection(conn);
            LisaApiStats.setErrorCallback(function(err, response) {
                if (err.name == "NoCdrDownloadRightsError") {
                    $('#error_text').text("De user heeft geen rechten om CDRs te downloaden. Voor sommige statistieken worden gebeurtenissen die voor de start van het wallboard plaatsgevonden hebben, niet meegenomen.");
                    $('#error').modal();
                }
            });

            // Get the company-model
            //conn.getModel().done(modelReady);
            conn.getModel().done(compassHandler.modelReady);
            conn.getCompanyName().done(function(companyName){COMPANYNAME = companyName});
        });
    }

    exports.logout = logout;

    function connectionStatusCallback(status) {
        if (status == Strophe.Status.AUTHFAIL) {
            alert("Inloggen mislukt. Voer uw login en wachtwoord opnieuw in, en probeer het nog een keer.");
            logout();
        }
    }

}).call(this);
