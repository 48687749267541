(function() {
    var settingsShown = false;

    $(document).ready(function() {
        $("#settings_button").click(function () {
            console.log("Settings clicked.");
            settingsClicked();
        });

        $('#admin_submit').click(function() {
            console.log("admin submit clicked");
            adminSubmitClicked();
            return false;
        });

        $('#admin').on('shown.bs.modal', function () {
            // Make some table headers sticky
            // We need to do this on modal show because otherwise the floatThead corrupts the queue_settings table.
            function tableContainer(table) {
                return table.closest('.table-responsive');
            }
            $('#queue_settings').floatThead({scrollContainer: tableContainer});
            $('#column_settings').floatThead({scrollContainer: tableContainer});
        });
    });

    function settingsClicked() {
        console.log("Opening settings screen.");
        if (!loggedIn || (settingsShown)) {
            if (!loggedIn) console.log("Not logged in");
            if (settingsShown) console.log("Settings already shown");
            return;
        }

        settingsShown = true;

        // Build up the screen
        for (var queueId in model.queues) {
            var queue = model.queues[queueId];
            $("#admin").collapse("toggle");
            $(".queue_settings").append(queueSettingHtml(queue));
        }
        $('#topclockenable').prop('checked',( queueSettings ? (queueSettings.topClock ? queueSettings.topClock : false) : false));
        $('#topdateenable').prop('checked',( queueSettings ? (queueSettings.topDate ? queueSettings.topDate : false) : false));
        $('#toprow').prop('checked',( queueSettings ? (queueSettings.topRow ? queueSettings.topRow : false) : false));
        $('#title_first_tile').val((queueSettings) ? (queueSettings.first_tile_title ? queueSettings.first_tile_title : "") : "");
        $('#text_first_tile').val((queueSettings) ? (queueSettings.first_tile_text ? queueSettings.first_tile_text : "") : "");

        // Get shown columns
        if (queueSettings) {
            Object.keys(queueSettings.showColumns).forEach(function (key) {
                $('#' + key).prop('checked', queueSettings.showColumns[key]);
            });
        }

        // Get messages
        newsMessages.forEach(function (message) {
            adminAddMessage(message);
        });
        $('.add-message button').on('click', function () {
           adminAddMessage();
        });
    }
    exports.settingsClicked = settingsClicked;

    function adminAddMessage(message) {
        var elem = $('<tr class="message"><td><div class="input-group input-group-lg">' +
            '<input type="text" class="form-control message">' +
            '<span class="input-group-btn"><button class="btn btn-default remove-message" type="button">' +
            '<span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button></span>' +
            '</div></td></tr>');
        if (message) {
            elem.find('input.message').val(message);
        }
        elem.find('button.remove-message').on('click', function () {
            $(this).parents('tr.message').remove();
        });
        $('.add-message').before(elem);
    }

    function adminSubmitClicked() {

        var settings = {};

        for (var queueId in model.queues) {
            var queue = model.queues[queueId];
            var queueSetting = settings[queue.id] = {};

            queueSetting.name = queue.name;

            queueSetting.enabled = $('#enabled-' +queue.id).is(':checked');
            queueSetting.beschikbaar = parseInt($('#beschikbaar-' +queue.id+ '-max').val());
            queueSetting.bezet = parseInt($('#bezet-' +queue.id+ '-max').val());
            queueSetting.wachtende = parseInt($('#wachtende-' +queue.id+ '-max').val());
            queueSetting.slaMaxWachttijd = parseInt($('#sla-max-wachttijd-' + queue.id).val());
            queueSetting.slaMaxWachtenden = parseInt($('#sla-max-wachtenden-' + queue.id).val());
            queueSetting.slaGemWachttijd = parseInt($('#sla-gem-wachttijd-' + queue.id).val());

            console.log("for Queue: " + queue.name );//+ " enabled: " + enabled + ", beschikbaar: " + beschikbaar + ", bezet: " + bezet + ", wachtende: " + wachtende);
            console.log(queueSetting);
        }
        settings.topClock = $('#topclockenable').is(":checked");
        settings.topDate = $('#topdateenable').is(":checked");
        settings.topRow = $('#toprow').is(":checked");
        settings.first_tile_title = $('#title_first_tile').val();
        settings.first_tile_text = $('#text_first_tile').val();

        // Set shown columns
        settings.showColumns = {};
        if (queueSettings) {
            Object.keys(queueSettings.showColumns).forEach(function (key) {
                settings.showColumns[key] = $('#' + key).is(':checked');
            });
        }

        var settingsResult = remoteStorage.setItem("wallboard-settings", settings, "user");

        // Set messages
        var messages = [];
        $('input.message').each(function(){
            messages.push($(this).val());
        });
        var messagesResult = remoteStorage.setItem("wallboard-messages", messages, "user");

        $.when(settingsResult, messagesResult).done(function(){location.reload();});
    }

    function queueSettingHtml(queue) {
        var myQueueSettings = null;
        if (queueSettings) {
            myQueueSettings = queueSettings[queue.id];
        }

        var settings = myQueueSettings || {};
        return  '<tr><td>'+queue.name+'</td>' +
                '<td><input type="checkbox" id="enabled-' +queue.id+'" name="enabled-' +queue.id+'" '+(settings.enabled ? "checked" : "")+'></td>' +
                '<td><input type="number" id="wachtende-' +queue.id+ '-max" class="form-control input-lg" min="1" max="1000" value="'+(settings.wachtende || '')+'"></td>' +
                '<td><input type="number" id="sla-max-wachttijd-' +queue.id+ '" class="form-control input-lg" min="1" max="1000" value="'+(settings.slaMaxWachttijd || '')+'"></td>' +
                '<td><input type="number" id="sla-max-wachtenden-' +queue.id+ '" class="form-control input-lg" min="1" max="1000" value="'+(settings.slaMaxWachtenden || '')+'"></td>' +
                '<td><input type="number" id="sla-gem-wachttijd-' +queue.id+ '" class="form-control input-lg" min="1" max="1000" value="'+(settings.slaGemWachttijd || '')+'"></td>';
    }


}).call(this);
