(function() {
    /* Dependencies */

    // Strophe
    _ = require("underscore")._;
    Backbone = require("backbone");

    require("strophe");
    require("../lib/strophe.pubsub.js");

    // For the Compass dashboard.
    // LisaApi
    Lisa = require("lisa-api").Lisa;

    // Constants
    var LOGIN_PLACEHOLDER_MARGIN = 20;  // XXX: Hack to fix small visual inconsistency between login placeholder image
                                        // and real dashboard
    var NEWS_MESSAGE_DELAY = 10 * 1000; // (ms) how long news messages are shown
    var NEWS_REFRESH_DELAY = 60 * 1000; // (ms) how often news messages are refreshed

    /* Authentication data */

    //var auth = require("./auth.js");

    var dashingLoaded = false;
    newsMessages = [];

    $(document).ready(function() {
        // Fit the faded out dashboard image
        startFitLoginPlaceholder();
    });

    function modelReady(newModel) {
        // initialize the global datamodel object.
        model = newModel;
        loggedIn = true;

        // Retrieve the company-name
        conn.getCompanyName().done(function(companyName){
            COMPANYNAME = companyName;
            retrieveSettings(); // Once we have the company-name, we can retrieve the settings.
        });

        /*
         * When new users or queues are added, make sure to listen to those as well.
         * We do this by listening for changes on the user-list and the queue-list.
         * Whenever either changes, we re-subscribe to changes for every element in the queue.
         */
        model.userListObservable.addObserver(function() {
            listenToAllUsers(model);
        });
        model.queueListObservable.addObserver(function() {
            listenToAllQueues(model);
        });

    }
    exports.modelReady = modelReady;

    function retrieveSettings() {
        var messagesDeferred = downloadNewsMessages();
        var usersDeferred = remoteStorage.getItem("wallboard-users", "user");
        var settingsDeferred = remoteStorage.getItem("wallboard-settings", "user");
        $.when(messagesDeferred, usersDeferred, settingsDeferred).always(function (messagesResult, usersResult, settingsResult) {
            try {
                newsMessages = messagesResult || [];

                whitelistedUsers = usersResult || [];
                //if (whitelistedUsers.indexOf(USERNAME) == -1) {
                    //alert("Inloggen mislukt. U heeft geen toegang tot het dashboard.");
                    //login.logout();
                //}

                queueSettings = settingsResult;
                if (queueSettings == null) {
                    console.log("queueSettings == null");
                    //$('#container').show();
                    admin.settingsClicked();
                    return;
                }
                console.log("Retrieved queue-settings from server.");
                console.log(queueSettings);
                augmentSettings();

                // When we have the settings, we can initialize dashing.
                initHeading();
                for (var queueId in model.queues) {
                    var queue = model.queues[queueId];
                    newQueue(queue);
                }

                initializeDashing();

            } catch (err) {
                console.log("Error reading settings from server.");
                console.log(err);
                queueSettings = null;
                admin.settingsClicked();
            }


        });

    }

    function augmentSettings() {
        if (!queueSettings.showColumns) queueSettings.showColumns = {};
        var defaults = {
            totalAgents: true,
            availableAgents: true,
            waitingCalls: true,
            callsToday: true,
            avgWaitTime: true,
            maxWaitTime: true,
            avgWaitTimeToday: true
        };
        // Keep only columns that we know
        queueSettings.showColumns = _.pick(queueSettings.showColumns, Object.keys(defaults));
        _.defaults(queueSettings.showColumns, defaults);
    }

    function initializeDashing() {
        // Load dashing
        if (dashingLoaded == false) {
            loadDashing();
            $('#container').show();
        }

        // Start fitting the dashboard into the browser window
        Dashing.on('ready-resize', function () {
            setTimeout(startFitDashboard, 0);
            setTimeout(startFitDashboard, 1000);
        });

        // Give dashing 5 seconds to load everything.
        _.delay(function() {
            dashingLoaded = true;

            // Listen for changes in all users and queues. (Don't do this until all gui-items actually exist)
            listenToAllUsers(model);
            listenToAllQueues(model);

            // Load initial data for all queues.
            for (var queueId in model.queues) {
                var queue = model.queues[queueId];
                handleQueueChange(queue);
            }

            // Get the text in the first tile.
            Dashing.widgets["welcome"][0].receiveData({title: queueSettings.first_tile_title ? queueSettings.first_tile_title : "" , text: queueSettings.first_tile_text ? queueSettings.first_tile_text : ""});

            // Make news widget cycle through messages
            // TODO: this should really be a specialized widget
            var newsMessageIndex = 0;
            function showNextNewsMessage() {
                if (newsMessages.length == 0) return;
                if (newsMessageIndex >= newsMessages.length) newsMessageIndex = 0;
                Dashing.widgets['news-messages'][0].receiveData({title: newsMessages[newsMessageIndex]});
                newsMessageIndex++;
            }
            function refreshNewsMessages() {
                downloadNewsMessages().done(function (result) {
                    newsMessages = result || [];
                });
            }
            setInterval(showNextNewsMessage, NEWS_MESSAGE_DELAY);
            setInterval(refreshNewsMessages, NEWS_REFRESH_DELAY);
            showNextNewsMessage();

        }, 5000);
    }

    function downloadNewsMessages() {
        return remoteStorage.getItem("wallboard-messages", "user");
    }

    // Automatically fit the dashboard to the browser window horizontally
    function fitDashboard() {
        var scale = $('#gridster-dashboard').width() / $('.gridster').width();
        $('#gridster-dashboard').css('transform', 'scale(' + scale + ',' + scale +')');
        $('#gridster-dashboard').css('height', $('.gridster').height() * scale);
    }
    function startFitDashboard() {
        $('#gridster-dashboard').css({'transform-origin': 'top left'});
        $(window).resize(fitDashboard);
        setTimeout(function () {
            fitDashboard();
            fitDashboard();  // Fit again to compute the width correctly in case scaling removes the scrollbar
        }, 0);
    }

    function fitLoginPlaceholder() {
        var scale = ($('#login-placeholder').width() + LOGIN_PLACEHOLDER_MARGIN) / $('#login-placeholder-image').width();
        $('#login-placeholder').css('transform', 'scale(' + scale + ',' + scale +')');
    }
    function startFitLoginPlaceholder() {
        $('#login-placeholder').css({'transform-origin': 'top left'});
        $(window).resize(fitLoginPlaceholder);
        fitLoginPlaceholder();
    }

    function listenToAllUsers(model) {
        for (userId in model.users) {

            // the model.users array contains all users, keyed by user-id.
            var user = model.users[userId];

            // Then, subscribe or re-subscribe to changes on the user.
            user.observable.addObserver(handleUserChange);
        }
    }

    function listenToAllQueues(model) {
        for (queueId in model.queues) {

            var queue = model.queues[queueId];
            queue.observable.addObserver(handleQueueChange);
            queue.stats.observable.addObserver(handleQueueChange);
        }
    }

    var handleUserChange = function(user) { //function handleUserChange(user) {
        //console.log("*** user " + user + " changed.");
        for(var queueId in user.queues) {
            var queue = user.queues[queueId];
            handleQueueChange(queue);
        }
    }

    function handleQueueChange(queue) {
        var myQueueSettings = null;
        if (queueSettings) {
            myQueueSettings = queueSettings[queue.id];
        }
        if (!myQueueSettings || (myQueueSettings.enabled == false)) {
            return; // Don't load this queue if it has been disabled in the settings.
        }

        // Event got triggered before the framework is loaded. Ignore.
        if (dashingLoaded == false) {
            return;
        }

        if (queueSettings.showColumns.callsToday) {
            if (queue.stats) {
                Dashing.widgets['gesprekken-vandaag-' + queue.id][0].receiveData({
                    number1: null,
                    number2: queue.stats.dailyPickedUpCalls,
                    number3: queue.stats.dailyCalls
                });
            } else {
                console.log("Not updating widget 'callsToday', because stats not initialized for queue " + queue.name);
            }
        }

        if (queueSettings.showColumns.avgWaitTimeToday) {
            if (queue.stats) {
                var value = (queue.stats.dailyCalls > 0) ? queue.stats.dailySumWaitingTime / queue.stats.dailyCalls : 0;
                var widgetId = 'gemiddelde-wachttijd-vandaag-' + queue.id;
                Dashing.widgets[widgetId][0].receiveData({duration: parseInt(value)});                                        // Duration
                showWidgetSlaWarning(widgetId, value, myQueueSettings.slaGemWachttijd);
            } else {
                console.log("Not updating widget 'callsToday', because stats not initialized for queue " + queue.name);
            }
        }

        var agentsAvailable = 0,
            agentsOccupied = 0,
            agentsPaused = 0,
            agentsTotal = 0;

        for (var userId in queue.users) {
            var user = queue.users[userId];
            if (user.loggedIn == false) {
                continue;
            }
            agentsTotal++;

            var occupied = false;
            for (callKey in user.calls) {
                var call = user.calls[callKey];
                if (call.state == "ANSWERED") {
                    occupied = true;
                    break;
                }
            }

            if (occupied){
                agentsOccupied++;
            // Combination of api v2 and api v3, user.pausedForQueue[queue.id] will be depreciated with the phasing out of api v2:
            } else if (user.pausedForQueue[queue.id] || user.receiveCalls !== 'ALL') {
                agentsPaused++;
            } else {
                agentsAvailable++;
            }
        }

        if (queueSettings.showColumns.totalAgents) {
            Dashing.widgets['totaal-' + queue.id][0].receiveData({current: parseInt(agentsTotal)});
        }

        if (queueSettings.showColumns.availableAgents) {
            Dashing.widgets['beschikbaarbezet-' + queue.id][0].receiveData({number1: parseInt(agentsAvailable),
                                                                            number2: parseInt(agentsOccupied),
                                                                            number3: parseInt(agentsPaused)});
        }

        if (queueSettings.showColumns.waitingCalls) {
            var widgetId = 'wachtende-' + queue.id;
            var value = _.size(queue.calls);
            Dashing.widgets[widgetId][0].receiveData({value: value});                                           // Meter
            showWidgetSlaWarning(widgetId, value, myQueueSettings.slaMaxWachtenden);
        }

        if (queueSettings.showColumns.avgWaitTime || queueSettings.showColumns.maxWaitTime) {
            var waitingTimeSum = 0;
            var maxWaitTime = 0;
            for (var callKey in queue.calls) {
                var call = queue.calls[callKey];
                var startTime = parseInt(call.destination.find('timeCreated').text()); // seconds since epoch
                var curWaitingTime = Date.now() / 1000 - startTime;
                waitingTimeSum  += curWaitingTime;
                if (curWaitingTime > maxWaitTime) maxWaitTime = curWaitingTime;
            }
        }

        if (queueSettings.showColumns.avgWaitTime) {
            var widgetId = 'gemiddelde-wachttijd-' + queue.id;

            var numCalls = _.size(queue.calls);
            var averageWaitingTime = waitingTimeSum / ((numCalls) ? numCalls : 1);

            Dashing.widgets[widgetId][0].receiveData({startTime: durationToStartTime(averageWaitingTime)});      // Number
            showWidgetSlaWarning(widgetId, averageWaitingTime, myQueueSettings.slaGemWachttijd);
        }

        if (queueSettings.showColumns.maxWaitTime) {
            var widgetId = 'wachttijd-' + queue.id;
            Dashing.widgets[widgetId][0].receiveData({startTime: durationToStartTime(maxWaitTime)});          // Timeinterval
            showWidgetSlaWarning(widgetId, maxWaitTime, myQueueSettings.slaMaxWachttijd);
        }
    }

    function durationToStartTime(duration) {
        var duration = parseFloat(duration);
        var timeMoment;
        if (duration === 0) {
            return 0;
        } else {
            return moment.utc(moment.utc().valueOf() - (duration * 1000)).valueOf();
        }
    }

    function showWidgetSlaWarning(widgetId, value, maxValue) {
      var widgetElement = $('.' + widgetId);
        if (maxValue && value > maxValue) {
            widgetElement.addClass('status-danger');
        } else {
            widgetElement.removeClass('status-danger');
        }
    }

    function initHeading() {

        // Count the number of columns that we want to show
        var showColumns = Object.keys(queueSettings.showColumns).filter(function (key) {
            return queueSettings.showColumns[key];
        });

        // This is for showing a clock above the tiles, next to the settings and logout button. Useful for showing the clock even if the top row of tiles is hidden.
        if (queueSettings.topClock === true) {
            document.getElementById('topclock').classList.add("topclockshow");
            }

        // This is for showing the date above the tiles, next to the settings and logout button. Useful for showing the date even if the top row of tiles is hidden.
        if (queueSettings.topDate === true) {
            document.getElementById('topdate').classList.add("topdateshow");
        }

            //This is for when the top row of tiles needs to be hidden, using the option in the settings menu.
        if (queueSettings.topRow === true) {
            document.querySelector('[data-id="welcome"]').parentElement.remove();
            document.querySelector('[data-id="news-messages"]').parentElement.remove();
            var columnCount = showColumns.length + 1;
            // Columns are added here
            var i = 0;
            while (i < columnCount) {
                i++;
            }
            window.numColumns = i;
        } else {
            var columnCount = showColumns.length + 1;
            // First three columns are already added in HTML, fill in extra columns
            var i = 4;
            while (i < columnCount) {
                $('.widgetList').append('<li data-row="1" data-col="' + i + '" data-sizex="1" data-sizey="1">' +
                    '<div data-id="welcome-' + i + '" data-view="Text" data-title="" data-text=""></div></li>');
                i++;
            }
            // End with clock widget
            $('.widgetList').append('<li data-row="1" data-col="' + i + '" data-sizex="1" data-sizey="1">' +
                '<div data-id="clock" data-view="Clock" data-title="" data-text=""></div></li>')
            // Tell Dashing how many columns
            window.numColumns = i;
        }
    }

    function newQueue(queue) {
        var myQueueSettings = null;
        if (queueSettings) {
            myQueueSettings = queueSettings[queue.id];
        }
        if (myQueueSettings && (myQueueSettings.enabled == false)) {
            console.log("queue " + queue + " disabled in settings. Not adding widgets.");
            return; // Don't load this queue if it has been disabled in the settings.
        }

        console.log("Got a new queue: " + queue + ", adding widgets for queue.");

        //$( ".widgetList").append( '<li data-row="1" data-col="1" data-sizex="1" data-sizey="1"><div data-id="welcome"
        // data-view="Text" data-title="'+queue.name+'"></div></li>' );
        $( ".widgetList").append(text("text-" + queue.id, queue.name));
        var columnCount = 1;
        if (queueSettings.showColumns.totalAgents) {
            $(".widgetList").append(agentenTotaal(queue));
            columnCount++;
        }
          if (queueSettings.showColumns.availableAgents) {
              $(".widgetList").append(agentenBeschikbaarBezet(queue));
              columnCount++;
        }
        if (queueSettings.showColumns.waitingCalls) {
            $(".widgetList").append(wachtende(queue, (myQueueSettings) ? myQueueSettings.wachtende : undefined));
            columnCount++;
        }
        if (queueSettings.showColumns.callsToday) {
            $(".widgetList").append(totaalGesprekken(queue));
            columnCount++;
        }
        if (queueSettings.showColumns.avgWaitTime) {
            $(".widgetList").append(gemiddeldeWachttijdPerQueue(queue));
            columnCount++;
        }
        if (queueSettings.showColumns.maxWaitTime) {
            $(".widgetList").append(wachttijdPerQueue(queue));
            columnCount++;
        }
        if (queueSettings.showColumns.avgWaitTimeToday) {
            $(".widgetList").append(gemiddeldeWachttijdVandaagPerQueue(queue));
            columnCount++;
        }

        // Fill any remaining empty columns
        for (var i = columnCount; i < window.numColumns; i++) {
            $('.widgetList').append('<li data-row="1" data-col="1" data-sizex="1" data-sizey="1"><div data-view="Text" data-title="" data-text=""></div></li>');
        }
    }

    /*** Html helper functions ***/
    function agentenTotaal(queue) {
        return number("totaal-" + queue.id, "Aantal Agenten", "");
    }

    function agentenBeschikbaarBezet(queue) {
        return dualnumber("beschikbaarbezet-" + queue.id, "Agenten", /*queue.name*/"", "beschikbaar", "bezet", "gepauzeerd");
    }

    function agentenBeschikbaarMeter(queue, max) {
        max = max || 10;
        return meter("beschikbaar-" + queue.id, "Agenten Vrij", /*queue.name*/"", 0, max);
    }

    function agentenBezetMeter(queue, max) {
        max = max || 10;
        return meter("bezet-" + queue.id, "Agenten Bezet", /*queue.name*/"", 0, max);
    }

    /*function gesprekkenOnbeantwoordMeter(queue) {
        return number("onbeantwoord-" + queue.id, "Gesprekken onbeantwoord", queue.name);
    }*/

    function wachtende(queue, max) {
        max = max || 20;
        return meter("wachtende-" + queue.id, "Aantal Wachtenden", /*queue.name*/"", 0, max);
    }

    function totaalGesprekken(queue) {
        return dualnumber("gesprekken-vandaag-" + queue.id, "Gesprekken vandaag", /*queue.name*/"", "", "opgenomen", "totaal");
    }

    function gemiddeldeWachttijdPerQueue(queue) {
        return timeinterval("gemiddelde-wachttijd-" + queue.id, "Gemiddelde Wachttijd", /*queue.name*/"");
    }

    function wachttijdPerQueue(queue) {
        return timeinterval("wachttijd-" + queue.id, "Maximale Wachttijd", /*queue.name*/"");
        //return graph("wachttijdgraph-" + queue.id, "Maximale Wachttijd", /*queue.name*/"");
    }

    function gemiddeldeWachttijdVandaagPerQueue(queue) {
        return duration("gemiddelde-wachttijd-vandaag-" + queue.id, "Gemiddelde Wachttijd Vandaag", /*queue.name*/"");
    }

    function meter(dataId, title, moreInfo, dataMin, dataMax) {
        dataMin = dataMin || 0;
        dataMax = dataMax || 10;
        var str = liStart() + '<div data-id="'+dataId+'" data-view="Meter" data-title="'+title+'" data-moreinfo="'+moreInfo+'" data-min="'+dataMin+'" data-max="'+dataMax+'"></div>' + liEnd();
        return str;
    }

    function number(dataId, title, moreInfo) {
        return liStart() + '<div data-id="'+dataId+'" data-view="Number" data-title="' + title + '" data-moreinfo="'+ moreInfo + '" data-prefix=""></div>' + liEnd();
    }

    function dualnumber(dataId, title, moreInfo, prefix1, prefix2, prefix3) {
        prefix1 = prefix1 || "";
        prefix2 = prefix2 || "";
        prefix3 = prefix3 || "";
        return liStart() + '<div data-id="'+dataId+'" data-view="Dualnumber" data-title="' + title + '" data-moreinfo="'+ moreInfo + '" data-prefix1="' + prefix1 + '" data-prefix2="' + prefix2 + '" data-prefix3="' + prefix3 + '"></div>' + liEnd();
    }

    function text(dataId, title, text, moreInfo) {
        text = text || "";
        moreInfo = moreInfo || "";
        return liStart() + '<div data-id="'+dataId+'" data-view="Text" data-title="' + title + '" data-text="'+ text + '" data-moreinfo="'+ moreInfo + '"></div>' + liEnd();
        //<div data-id="welcome" data-view="Text" data-title="Hello" data-text="This is your shiny new Compass
        // Walldisplay." data-moreinfo="Protip: You can drag the widgets around!"></div>
    }

    function timeinterval(dataId, title, moreInfo) {
        title = title || "";
        moreInfo = moreInfo || "";
        return liStart() + '<div data-id="'+dataId+'" data-view="Timeinterval" data-title="' + title + '" data-moreinfo="'+ moreInfo + '"></div>' + liEnd();
    }

    function duration(dataId, title, moreInfo) {
        title = title || "";
        moreInfo = moreInfo || "";
        return liStart() + '<div data-id="'+dataId+'" data-view="Duration" data-title="' + title + '" data-moreinfo="'+ moreInfo + '"></div>' + liEnd();
    }

    function graph(dataId, title, moreInfo) {
        title = title || "";
        moreInfo = moreInfo || "";
        return liStart() + '<div data-id="'+dataId+'" data-view="Graph" data-title="' + title + '" data-moreinfo="'+ moreInfo + '"></div>' + liEnd();
    }

    function liStart(dataSizeX, dataSizeY) {
        dataSizeX = dataSizeX || 1;
        dataSizeY = dataSizeY || 1;
        return '<li data-row="1" data-col="1" data-sizex="'+dataSizeX+'" data-sizey="'+dataSizeY+'">';
    }

    function liEnd() {
        return '</li>';
    }

}).call(this);
